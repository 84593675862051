import React, { useContext, useEffect, useState } from 'react';
import {
    Typography,
    Grid,
    Button,
    FormControl,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { IdBill, IdLeaseAgreement, BillStatus, ITenantInfo } from 'realhaus-sdk';
import { RootStoreContext } from '../../../global/storeContext';
import { WalletSummaryText } from '../../wallet/walletWidget';
import { SendOrRequestPaymentModal } from './request/payment/send/sendOrRequestModal';
import { useIsMobible } from '../../uiComponents/UIComponents';
import { LeaseBillSnapshot } from './billSnapshot';
import { BillPaymentCheckout } from '../../wallet/billPaymentCheckout';

export const LeaseBillsComponent: React.FC<{
    leaseAgreement: IdLeaseAgreement;
    tenant: ITenantInfo | undefined;
}> = observer(({ leaseAgreement, tenant }) => {
    const billStatuses = ['Overdue', 'Pending', 'Paid', 'Canceled'];
    const { leaseStore, uiStore } = useContext(RootStoreContext);
    const [statusFilter, setStatusFilter] = React.useState<string[]>(billStatuses);
    const [bills, setBills] = useState<IdBill[]>([]);
    const [filteredBills, setFilteredBills] = useState<IdBill[]>([]);
    const [openSendRequestPaymentModal, setOpenSendRequestPaymentModal] = React.useState(false);
    const [openPaymentCheckoutModal, setOpenPaymentCheckoutModal] = useState(false);
    const [selectedBillId, setSelectedBillId] = useState<string | undefined>(undefined);

    const handleCloseSendRequestPaymentModal = () => {
        setOpenSendRequestPaymentModal(false);
    };

    const handleOpenSendRequestPaymentModal = () => {
        setOpenSendRequestPaymentModal(true);
    };

    const isLandlordMode = uiStore.isLandlordMode();

    useEffect(() => {
        // fetch lease bills;
        fetchBills();
    }, []);

    const fetchBills = async () => {
        const leaseBills = await leaseStore.getLeaseBills(leaseAgreement.id);
        setBills(leaseBills);
        setFilteredBills(leaseBills);
    };

    const filterBills = (filteredStatuses: string[]) => {
        let filtered = [...(bills ?? [])];
        if (filteredStatuses.length > 0) {
            filtered = filtered.filter((b) => {
                // get bills that status matches filter
                let billStatus = b.status.toString();
                if (billStatus === BillStatus.PENDING && b.dueDate < Date.now()) {
                    billStatus = 'Overdue';
                }

                return filteredStatuses.find((s) => s.toLowerCase() === billStatus.toLowerCase());
            });
        }
        setFilteredBills(filtered);
    };

    const handleStatusFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let filteredValues = event.target.value as string[];
        filteredValues = filteredValues.length === 0 ? billStatuses : filteredValues;
        setStatusFilter(filteredValues);
        filterBills(filteredValues);
    };

    const handlePaymentRequested = async () => {
        await fetchBills();
        filterBills(statusFilter);
        console.log('Payment Requested');
    };

    const closeBillPaymentCheckout = () => {
        setSelectedBillId(undefined);
        setOpenPaymentCheckoutModal(false);
    };
    const showPaymentCheckout = (billId: string) => {
        setSelectedBillId(billId);
        setOpenPaymentCheckoutModal(true);
    };

    const isMobile = useIsMobible();

    return (
        <>
            <Grid container spacing={2} direction='column'>
                <Grid item container justifyContent='space-between' spacing={1}>
                    <Grid item xs={12} md={4}>
                        {isLandlordMode && (
                            <Button
                                variant='outlined'
                                color='primary'
                                onClick={handleOpenSendRequestPaymentModal}
                            >
                                send/Request payment
                            </Button>
                        )}
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent={isMobile ? 'flex-start' : 'flex-end'}
                        xs={12}
                        md={8}
                    >
                        <Grid item>
                            <WalletSummaryText />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>Filter:</Grid>
                <Grid item>
                    <FormControl
                        variant='outlined'
                        size='small'
                        style={{ minWidth: '250px', maxWidth: '300px' }}
                    >
                        <Select
                            id='lease-status-filter'
                            variant='outlined'
                            multiple
                            value={statusFilter}
                            onChange={handleStatusFilterChange}
                            renderValue={(selected) => (selected as string[]).join(', ')}
                        >
                            {billStatuses.map((status) => (
                                <MenuItem key={status} value={status}>
                                    <Checkbox checked={!!statusFilter.find((f) => f === status)} />
                                    <ListItemText primary={status} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    {/* filtered Bills */}
                    {filteredBills?.length === 0 ? (
                        <Typography variant='subtitle1'>
                            {!!bills && bills?.length > 0
                                ? 'Could not find a lease bill that matches the filter'
                                : 'There are no bills available'}
                        </Typography>
                    ) : (
                        filteredBills?.map((bill) => (
                            <LeaseBillSnapshot
                                key={bill.id}
                                bill={bill}
                                onPayBillClicked={() => showPaymentCheckout(bill.id)}
                            />
                        ))
                    )}
                </Grid>
            </Grid>
            <SendOrRequestPaymentModal
                open={openSendRequestPaymentModal}
                handleCloseModal={handleCloseSendRequestPaymentModal}
                tenantName={tenant?.firstname}
                leaseAgreement={leaseAgreement}
                onPaymentRequested={handlePaymentRequested}
            />
            {!!selectedBillId && (
                <BillPaymentCheckout
                    billId={selectedBillId}
                    leaseId={leaseAgreement.id}
                    open={openPaymentCheckoutModal}
                    handleClose={closeBillPaymentCheckout}
                    handleComplete={() => {
                        closeBillPaymentCheckout();
                    }}
                    handleFailure={() => {
                        console.log('Bill Payment Failed');
                    }}
                />
            )}
        </>
    );
});
