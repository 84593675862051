import {
    Box,
    Card,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { format } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { IDTransaction, IWallet, TransactionType } from 'realhaus-sdk';
import { NumericFormat } from 'react-number-format';
import { RootStoreContext } from '../../global/storeContext';
import React from 'react';
import { WithdrawFundsButton } from './withdrawFundsButton';
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore';
import { DEFAULT_TRANSACTIONS_LIMIT } from '../../stores/walletStore';
import { useStyles } from './styles';
import StringUtils from '../../utils/string';
import { useIsMobible } from '../uiComponents/UIComponents';

export const AccountTransactionsView: React.FC<{
    transactions: IDTransaction[];
    canFetchMoreTransaction: boolean;
    getTransactions: () => Promise<void>;
}> = observer(({ transactions, canFetchMoreTransaction, getTransactions }) => {
    const classes = useStyles();
    const isMobile = useIsMobible();
    return (
        <>
            <Card variant='outlined' elevation={0}>
                <Box className={classes.container}>
                    <List>
                        {transactions.map((tranx, index) => (
                            <>
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            <Typography variant='body2'>
                                                {format(tranx.timestamp, 'PPp')}
                                            </Typography>
                                        }
                                        secondary={
                                            <>
                                                <Typography
                                                    variant='body1'
                                                    noWrap
                                                    style={{ color: 'black' }}
                                                >
                                                    {isMobile
                                                        ? StringUtils.truncateString(
                                                              tranx.notes,
                                                              22,
                                                          )
                                                        : StringUtils.truncateString(tranx.notes)}
                                                </Typography>
                                                <Typography
                                                    variant='button'
                                                    display='inline'
                                                    color={
                                                        tranx.type === TransactionType.CREDIT
                                                            ? 'primary'
                                                            : 'secondary'
                                                    }
                                                >
                                                    {tranx.type}
                                                </Typography>
                                            </>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                                            <NumericFormat
                                                value={tranx.amount}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                suffix=' CAD'
                                                decimalSeparator='.'
                                                fixedDecimalScale
                                                decimalScale={2}
                                            />
                                        </Typography>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                            </>
                        ))}
                        <ListItem
                            button
                            onClick={getTransactions}
                            disabled={!canFetchMoreTransaction}
                        >
                            <ListItemText inset primary=' Load more transactions...' />
                        </ListItem>
                    </List>
                </Box>
            </Card>
        </>
    );
});

export const WalletComponent: React.FC = observer(() => {
    const { uiStore, walletStore } = useContext(RootStoreContext);
    const [wallet, setWallet] = useState<IWallet | undefined>();
    const [transactions, setTransactions] = useState<IDTransaction[]>([]);
    const [lastQueryDoc, setLastQueryDoc] =
        React.useState<QueryDocumentSnapshot<DocumentData> | null>(null);
    const [canFetchMoreTransactions, setCanFetchMoreTransactions] = React.useState<boolean>(false);

    const getWalletTransactions = async () => {
        uiStore.showLoading();
        try {
            // fetch transactions
            await walletStore.getWalletTransactions(lastQueryDoc).then((wallet) => {
                if (!!wallet && wallet.transactions.length > 0) {
                    const trnxs = wallet.transactions.sort((a, b) => b.timestamp - a.timestamp);
                    setTransactions((prev) => [...prev, ...trnxs]);
                    wallet.transactions.length < DEFAULT_TRANSACTIONS_LIMIT
                        ? setCanFetchMoreTransactions(false)
                        : setCanFetchMoreTransactions(true);
                    setLastQueryDoc(wallet.lastDoc);
                } else {
                    setCanFetchMoreTransactions(false);
                }
            });
        } finally {
            uiStore.hideLoading();
        }
    };

    useEffect(() => {
        const init = async () => {
            uiStore.showLoading();
            try {
                // fetch wallet
                const w = await walletStore.getWallet();
                setWallet(w);
                await getWalletTransactions();
            } finally {
                uiStore.hideLoading();
            }
        };

        init();
    }, []);

    return (
        <>
            <Grid
                container
                justifyContent='space-between'
                style={{ marginTop: '24px', marginBottom: '40px' }}
                spacing={2}
            >
                <Grid item>
                    <div>
                        <Typography variant='body1' color='textSecondary' display='inline'>
                            BALANCE:
                        </Typography>
                        <Typography
                            variant='body1'
                            color='primary'
                            style={{ paddingLeft: '10px', fontWeight: 'bold' }}
                            display='inline'
                        >
                            <strong>
                                <NumericFormat
                                    value={wallet?.amount ?? 0}
                                    prefix='$ '
                                    suffix=' CAD'
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    decimalSeparator='.'
                                    fixedDecimalScale
                                    decimalScale={2}
                                />
                            </strong>
                        </Typography>
                    </div>
                </Grid>

                <Grid item>
                    <div>
                        <Typography display='inline'>
                            <WithdrawFundsButton updateWallet={getWalletTransactions} />
                        </Typography>
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='h6'>Recent Transactions</Typography>
                </Grid>
                <Grid item xs={12}>
                    <AccountTransactionsView
                        transactions={transactions}
                        canFetchMoreTransaction={canFetchMoreTransactions}
                        getTransactions={getWalletTransactions}
                    />
                </Grid>
            </Grid>
        </>
    );
});
