import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { InputFieldMoney } from '../formComponents/inputfieldmoney';
import { RootStoreContext } from '../../global/storeContext';
import { IFormattedBankConnection } from '../financials';
import { BankAccountPurpose, IWallet } from 'realhaus-sdk';
import StringUtils from '../../utils/string';

export const WithdrawFundsCheckout: React.FC<{
    open: boolean;
    handleClose: () => void;
    updateWallet: () => Promise<void>;
}> = observer(({ open, handleClose, updateWallet }) => {
    const [amount, setAmount] = React.useState<number>(0);
    const [disableWithdrawal, setDisableWithdrawal] = React.useState<boolean>(true);
    const [payoutAccounts, setPayoutAccounts] = React.useState<IFormattedBankConnection[]>([]);
    const [wallet, setWallet] = React.useState<IWallet>();

    const { bankAccountStore, uiStore, walletStore } = React.useContext(RootStoreContext);

    useEffect(() => {
        const init = async () => {
            uiStore.showLoading();
            try {
                //fetch user payout bank account
                await bankAccountStore
                    .getUserBankAccounts(BankAccountPurpose.PAYMENTS)
                    .then((accounts) => {
                        if (!!accounts) {
                            setPayoutAccounts(accounts.data);
                        }
                    });

                //get Wallet
                await walletStore.getWallet().then((w) => {
                    if (!!w) {
                        setWallet(w);
                    }
                });
            } finally {
                uiStore.hideLoading();
            }
        };

        init();
    }, []);

    const walletAmount = wallet?.amount ?? 0;
    const handleAmountChange = (val: string | number | null) => {
        let updatedVal = Number(val);
        if (isNaN(updatedVal) || updatedVal < 0) return;
        if (updatedVal > walletAmount) updatedVal = walletAmount;

        setAmount(updatedVal);
        setDisableWithdrawal(false);
    };

    const handleWithdrawal = () => {
        if (!payoutAccounts || payoutAccounts.length <= 0) {
            uiStore.error('Please setup withdrawal method');
            return;
        }

        if (amount === 0) {
            uiStore.error(`Please provide an amount above 0.`);
            return;
        }

        if (amount > walletAmount) {
            uiStore.error('Please provide an amount not more that your balance.');
            return;
        }

        try {
            uiStore.showLoading();
            walletStore.withdrawFunds(amount).then(async (success) => {
                if (success) {
                    uiStore.success(`Withdraw request for CAD${amount} initiated`);
                    await updateWallet();
                } else {
                    uiStore.error('Unable to process withdrawal at this time. Please try again');
                }
            });
        } catch (err) {
            uiStore.error('Unable to process withdrawal at this time. Please try again');
            console.error(err);
        } finally {
            handleClose();
            uiStore.hideLoading();
        }
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
                <DialogTitle>
                    <Typography variant='h6' align='center'>
                        <b>Withdraw Your Money</b>
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid container item xs={12} spacing={0} alignItems='center'>
                            <Grid container justifyContent='space-between' item xs={12}>
                                <Grid item>
                                    <Typography variant='body1' display='inline'>
                                        Amount
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant='body1' display='inline' align='right'>
                                        max: {StringUtils.asCurrency(walletAmount)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item container spacing={2}>
                                <Grid item xs={12}>
                                    <InputFieldMoney
                                        id='amount'
                                        value={amount}
                                        placeholder='Amount to Fund'
                                        fullWidth
                                        onChange={handleAmountChange}
                                        style={{ marginTop: '0', paddingTop: '0' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography>
                                        The amount <b>{StringUtils.asCurrency(amount)}</b> will be
                                        deposited into your{' '}
                                        <b>{payoutAccounts[0]?.institution ?? ''} account.</b>{' '}
                                        Deposit could take between 2-5 business days.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ padding: '16px 24px' }}>
                    <Button color='primary' onClick={handleClose}>
                        cancel
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={handleWithdrawal}
                        disabled={amount === 0 || walletAmount === 0 || amount > walletAmount}
                    >
                        Withdraw
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});
